<template>
  <div class="bott-box">
    <div class="texts flex-row">
      <span class="mr">® 2024 muyuepro.com&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2023014969号-1</a></span>
      <span class="mr">长沙赛好信息科技有限公司 </span>
      <a href="#/protocol" target="_blank">用户协议</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bottomView',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.bott-box {
  width: 100%;
  padding: 0 48px;
  box-sizing: border-box;
  .texts {
    width: 100%;
    padding: 38px 0 34px;
    border-top: 1px solid #404040;
    justify-content: center;
    span,
    a {
      font-size: 12px;
      color: #ffffff;
      text-decoration: none;
      &.mr {
        margin-right: 48px;
      }
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>