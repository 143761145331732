import { get, postJson, postForm } from './request'
// 修改密码
export function editPassword(params) {
  return postJson('/mu-yue/auth/user/updatepwd', params)
}

// 发送验证码
export function sendSmsCode(params) {
  return postJson('/mu-yue/auth/user/send/sms', params)
}

// 校验验证码
export function checkSmsCode(params) {
  return postJson('/mu-yue/auth/user/checkCode', params)
}

// PC-（找回密码）设置新密码
export function setNewPassword(params) {
  return postJson('/mu-yue/auth/user/setnewpwd', params)
}

// 提交反馈
export function submitFeedback(params) {
  return postJson('/mu-yue/user/feedback/submit', params)
}

// 文件上传-意见反馈
export function uploadFileResouce(params) {
  return postForm('/mu-yue/file/UploadResource', params)
}

// 下载次数统计
export function downloadTongji(params) {
  return postJson('/mu-yue/pc/app/log', params)
}

// 下载次数统计
export function getNewVersion(params) {
  return get('/mu-yue/pc/version', params)
}

// 下载次数统计
export function getAndroidNewVersion(params) {
  return get('/mu-yue/app/version', params)
}

