import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homeView',
    component: HomeView
  },
  {
    path: '/protocol',
    name: 'protocolView',
    component: () => import('../views/protocol/index.vue')
  },
  {
    path: '/vip',
    name: 'vipView',
    component: () => import('../views/vip/index.vue')
  },
  {
    path: '/password/edit',
    name: 'editPassword',
    component: () => import('../views/password/edit.vue')
  },
  {
    path: '/password/find',
    name: 'editPasswordFind',
    component: () => import('../views/password/find.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
