<template>
  <div class="head flex-row bw" :class="{mobile: GetIsMobile}">
    <img src="../assets/logo.png" alt="幕跃Pro" title="幕跃Pro" @click="handleGo('homeView')">
    <div class="right" v-if="!GetIsMobile">
      <span class="txt" :class="{active: activeIndex === 1}" @click="handleGo('vipView')">产品功能</span>
      <span class="txt" :class="{active: activeIndex === 1}" @click="handleGo('application')">应用场景</span>
      <span class="txt" :class="{active: activeIndex === 2}" @click="goDownload">下载</span>
      <span class="txt" :class="{active: activeIndex === 3}" @click="handleGo('help')">教程&帮助</span>
    </div>
    <div class="right" v-if="GetIsMobile">
      <i class="el-icon-s-fold" @click="drawer = true"></i>
    </div>
    <el-drawer
      title=""
      :visible.sync="drawer"
      direction="rtl">
      <p class="rt-menu" :class="{active: activeIndex === 1}" @click="handleGo('vipView')">产品功能</p>
      <p class="rt-menu" :class="{active: activeIndex === 2}" @click="goDownload">下载</p>
      <p class="rt-menu" :class="{active: activeIndex === 3}" @click="handleGo('help')">教程&帮助</p>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'headerView',
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      drawer: false
    }
  },
  computed: {
    ...mapGetters(['GetIsMobile'])
  },
  methods: {
    handleGo(name) {
      if (name === 'help') {
        window.open('https://www.muyuepro.com/help/docs/muyue')
      } else if (name === 'service') {
        window.open('http://47.100.117.82:82/chatIndex?kefu_id=kefu2')
      } else if (name === 'application') {
        window.open('https://www.muyuepro.com/help/docs/muyue/changjing')
      } else {
        this.$router.push({
          name: name
        })
      }
    },
    goDownload() {
      this.$emit('goDownload')
    }
  }
}
</script>

<style lang="scss" scoped>
.head {
    padding: 6px 48px;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.9);
    img {
      width: 183px;
      height: 48px;
      cursor: pointer;
    }
    .right {
      .el-icon-s-fold {
        color: #FFFFFF;
        font-size: 20px;
      }
      .txt {
        color: #FFFFFF;
        font-size: 16px;
        cursor: pointer;
        font-weight: 500;
        margin-left: 48px;
        &.active {
          position: relative;
          &::after {
            content: "";
            display: inline-block;
            width: 16px;
            height: 4px;
            background-color: #505BD9;
            position: absolute;
            bottom: -8px;
            left: 50%;
            margin-left: -8px;
          }
        }
      }
    }
    &.mobile {
      padding: 6px 20px;
      .rt-menu {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
</style>