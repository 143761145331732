<template>
  <div class="home-view" ref="homeView" :class="{mobile: GetIsMobile}">
    <headView @goDownload="move2Bottom"></headView>
    <div class="cont-item flex-row bw">
      <div class="left ml">
        <p class="size1">手机和电脑之间的快速通道</p>
        <p class="size1 mb">电脑轻松浏览控制手机</p>
        <p class="size2 mb">手机高清投屏到电脑，电脑反向便捷控制手机</p>
        <p class="size3 mb">1080P高清投屏 | 鼠标/触控板控制 | 电脑键盘输入</p>
        <div class="btns flex-row">
          <el-button type="primary" icon="el-icon-download" @click="move2Bottom">立即下载</el-button>
          <a href="https://www.muyuepro.com/help/docs/muyue/muyue-1ermpph98caf6" target="_blank">更新日志</a>
        </div>
      </div>
      <div class="right">
        <video src="../../assets/cont1.mp4" muted="muted" autoplay="true" loop="loop"></video>
      </div>
    </div>
    <div class="cont-item flex-row bw">
      <div class="right tl">
        <video src="../../assets/cont2.mp4" muted="muted" autoplay="true" loop="loop"></video>
      </div>
      <div class="left ml tl">
        <p class="size1 mb">幕跃-办公、学习利器</p>
        <p class="size2 mb">轻松获取手机信息，手机电脑信息互通，两台设备和二为一，不再手忙脚乱</p>
        <p class="size3">快速采集手机APP信息 | 剪切板共享 | 手机文件分类查看 |  | 截屏&录屏</p>
      </div>
    </div>
    <div class="cont-item flex-row bw">
      <div class="left ml">
        <p class="size1 mb">幕跃-多场景娱乐</p>
        <p class="size2">电脑也能玩手机游戏、电脑也能看手机视频，电脑也能看手机股票软件；</p>
        <p class="size2">手机息屏，电脑可继续操作手机；</p>
        <p class="size2 mb">手机网络访问，不留任何痕迹在电脑；</p>
        <p class="size3">手机息屏 | 竖屏/横屏自适应 | 键盘映射</p>
      </div>
      <div class="right">
        <video src="../../assets/cont3.mp4" muted="muted" autoplay="true" loop="loop"></video>
      </div>
    </div>
    <div class="cont-item flex-row bw">
      <div class="right tl">
        <video src="../../assets/cont4.mp4" muted="muted" autoplay="true" loop="loop"></video>
      </div>
      <div class="left ml tl">
        <p class="size1 mb">幕跃-多控中心</p>
        <p class="size2">一台电脑，轻松连接控制多台设备</p>
        <p class="size2">多种连接方式，数据线、WIFI均可连接</p>
      </div>
    </div>
    <div class="download-box flex-row">
      <div class="left">
        <img src="../../assets/logo-text.png" alt="">
      </div>
      <div class="right">
        <div class="item mb">
          <div class="top flex-row">
            <span class="bold">PC端</span>
            <a href="https://www.muyuepro.com/help/docs/muyue/muyue-1ermpph98caf6" target="_blank">更新日志</a>
          </div>
          <div class="btns flex-row">
            <div class="btn flex-row" @click="downloadPack(winDownlaodUrl, 1)">
              <img src="../../assets/windows.png" alt="">
              <span>Windows</span>
              <span class="small">V{{currVersion}}正式版</span>
            </div>
            <div class="btn ml flex-row" @click="downloadPack(directlaodUrl, 3)">
              <img src="../../assets/windows.png" alt="">
              <span>Windows</span>
              <span class="small">V{{currVersion}}免安装版</span>
            </div>
            <div class="btn ml flex-row" @click="downloadPack(macDownlaodUrl, 2)">
              <img src="../../assets/pingguo.png" alt="">
              <span>MacOS</span>
              <span class="small">V0.8.2公测版</span>
            </div>
          </div>
          <p class="bottom">*目前仅支持windows电脑（WIN10及以上版本）或苹果mac电脑对安卓\鸿蒙手机进行控制；免安装版可直下载解压可用，适用于电脑有限制不能安装软件的场景；</p>
        </div>
        <div class="item">
          <div class="top flex-row">
            <span class="bold">移动端</span>
          </div>
          <div class="btns flex-row">
            <div class="btn flex-row" @click="downloadPack(androidDownlaodUrl, 4)">
              <img src="../../assets/anzhuo.png" alt="">
              <span>Android</span>
            </div>
          </div>
          <p class="bottom">*安卓版无需单独下载安装，可首次配置手机环境时由PC版引导安装至你的安卓手机</p>
        </div>
      </div>
    </div>
    <bottomView></bottomView>
    <div class="sidebar">
      <div class="item mb" @click="showFeedback = !showFeedback">
        <img src="../../assets/feedback.png" alt="">
        <span>问题反馈</span>
      </div>
      <div class="item mb" @click="showWechatService = !showWechatService">
        <img src="../../assets/services.png" alt="">
        <span>在线咨询</span>
      </div>
      <div class="item" @click="showWechatCode = !showWechatCode">
        <img src="../../assets/wechat.png" alt="">
        <span>公众号</span>
      </div>
    </div>
    <div class="feedback-box" v-show="showFeedback">
      <p class="tit">问题反馈</p>
      <div class="line flex-row bw mb">
        <div class="left">
          <span class="red">*</span>
          <span>问题类型：</span>
        </div>
        <div class="right">
          <el-select v-model="questionType" placeholder="请选择">
            <el-option
              v-for="item in questionList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="line flex-row bw mb">
        <div class="left">
          <span class="red">*</span>
          <span>反馈描述：</span>
        </div>
        <div class="right"></div>
      </div>
      <textarea class="textarea" placeholder="请输入反馈描述" v-model="questionDesc"></textarea>
      <div class="line flex-row bw mb">
        <div class="left">
          <span>上传附件或截图：</span>
        </div>
        <div class="right">
          <span class="box flex-row" @click="handleUpload">点击上传附件或截图</span>
        </div>
      </div>
      <div class="imgs">
        <div class="imgs-item" v-for="(item, index) in uploadList" :key="index">
          <img :src="item.url" class="img" alt="">
          <img src="../../assets/close.png" class="close" alt="" @click="removeImg(index)">
        </div>
      </div>
      <div class="line flex-row bw mbl">
        <div class="left">
          <span>联系方式：</span>
        </div>
        <div class="right">
          <input type="text" class="concat" v-model="contact" placeholder="邮箱、手机号、QQ、微信等">
        </div>
      </div>
      <div class="btns flex-row bw">
        <span class="btn full flex-row" @click="submitFeedback">提交反馈</span>
        <span class="btn normal flex-row" @click="showFeedback = false">下次再说</span>
      </div>
    </div>
    <div class="wechat-code" v-show="showWechatCode">
      <img src="../../assets/wechat-code.jpg" alt="">
    </div>
    <div class="wechat-service" v-show="showWechatService">
      <img src="../../assets/wechat-code.jpg" alt="">
      <span>关注公众号，在线联系客服</span>
    </div>
    <div class="back-up" v-show="showFeedback || showWechatCode || showWechatService" @click="closeBackup"></div>
    <input type="file" ref="uploadInput" class="upload-input" @change="uploadChange">
  </div>
</template>

<script>
import { submitFeedback, uploadFileResouce, downloadTongji, getNewVersion,getAndroidNewVersion } from '@/service/api'
import headView from '../../components/header.vue'
import bottomView from '../../components/bottom.vue'
import { mapGetters } from 'vuex'
import { baiduAnalytics } from '../../utils/analytics.ts'
export default {
  name: 'homeView',
  components: {
    headView,
    bottomView
  },
  data() {
    return {
      showWechatService: false,
      showWechatCode: false,
      showFeedback: false,
      questionType: '',
      questionDesc: '',
      contact: '',
      questionList: [
        {
          value: '配置连接问题',
          label: '配置连接问题'
        },
        {
          value: '功能BUG',
          label: '功能BUG'
        },
        {
          value: '优化建议',
          label: '优化建议'
        },
        {
          value: '新需求',
          label: '新需求'
        },
        {
          value: '其他问题',
          label: '其他问题'
        }
      ],
      uploadList: [],
      currVersion: '0.8.0',
      winDownlaodUrl: 'https://muyuepro.com/download/muyuepro-win32-0.8.0.zip',
      directlaodUrl: 'https://muyuepro.com/download/muyuepro-win32-0.8.0.zip',

      macDownlaodUrl: 'https://muyuepro.com/download/muyuepro-0.8.2-arm64-mac.zip',
      androidDownlaodUrl: 'https://muyuepro.com/download/muyue-1.0.23.apk.zip'
    }
  },
  computed: {
    ...mapGetters(['GetIsMobile'])
  },
  mounted() {
    let timestamp = localStorage.getItem('operate')
    let randomNum= ''
    let times = ''
    if(!timestamp){
      randomNum= Math.floor(Math.random()*1000000)
      times = (new Date()).valueOf();

      localStorage.setItem('operate',times.toString()+randomNum.toString())
    }

    downloadTongji({
      deviceid: timestamp?timestamp:times.toString()+randomNum.toString(),
      operate: 'websiteVisitors'
    })

    const { down } = this.$route.query
    if ([1, '1'].includes(down)) {
      setTimeout(() => {
        this.move2Bottom()
      }, 300);
    }
    if ([2, '2'].includes(down)) {

      window.location.replace('https://www.muyuepro.com/help/docs/muyue/shoujis')
    }
    if ([3, '3'].includes(down)) {
      window.location.replace('https://www.muyuepro.com/help/docs/muyue/huawei-s')
    }
    if ([4, '4'].includes(down)) {
      window.location.replace('https://www.muyuepro.com/help/docs/muyue/xiaomihongmi-s')
    }
    if ([5, '5'].includes(down)) {
      window.location.replace('https://www.muyuepro.com/help/docs/muyue/Vivo&iqoo-S')
    }
    if ([6, '6'].includes(down)) {
      window.location.replace('https://www.muyuepro.com/help/docs/muyue/muyue-1fg9f8jccmr7h')
    }
    if ([7, '7'].includes(down)) {
      window.location.replace('https://www.muyuepro.com/help/docs/muyue/qita-s')
    }
    if ([8, '8'].includes(down)) {
      window.location.replace('https://www.muyuepro.com/help/docs/muyue/wenti')
    }
    getNewVersion({
      version: this.currVersion
    }).then((res) => {
      if (res.hasNewVersion) {
        this.currVersion = res.maxVersion;
        if (res.maxVersionDownloadUrl) {
          const obj = JSON.parse(res.maxVersionDownloadUrl);
          console.log(obj)
          this.winDownlaodUrl = obj.windows;
          this.macDownlaodUrl = obj.macos;
          this.directlaodUrl = obj.windowsDirect
        }
      }
    })

    getAndroidNewVersion({
      version: 1
    }).then((res) => {
      this.androidDownlaodUrl = res.maxVersionDownloadUrl
    })
  },
  methods: {
    downloadPack(url, type) {
      const elt = document.createElement('a');
      elt.setAttribute('href', url);
      if (type === 1) {
        elt.setAttribute('download', `muyuepro-win32-${this.currVersion}.zip`);
      } else if(type === 2){
        elt.setAttribute('download', `muyuepro-0.8.2-arm64-mac.zip`);
      }else if(type === 3){
        elt.setAttribute('download', `muyuepro-unpacked-${this.currVersion}.zip`);
      } else if (type === 4) {
        const str = this.androidDownlaodUrl
        const lastSlashIndex = str.lastIndexOf("/");
        const result = str.substring(lastSlashIndex + 1);
        console.log(result);

        elt.setAttribute('download', result);
      }

      elt.style.display = 'none';
      document.body.appendChild(elt);
      elt.click();
      document.body.removeChild(elt);
      if (type === 1 || type===3) {
        downloadTongji({
          operate: 'windowsDownload'
        })
        baiduAnalytics.trackEvent({
          category: 'button',
          action: 'click',
          label: 'windows下载次数',
          value: 1,
        })
      } else if (type === 2) {
        downloadTongji({
          operate: 'macosDownload'
        })
        baiduAnalytics.trackEvent({
          category: 'button',
          action: 'click',
          label: 'macos下载次数',
          value: 1,
        })
      }

    },
    move2Bottom() {
      this.$nextTick(() => {
        let scrollEl = this.$refs.homeView
        scrollEl.scrollTo({ top: scrollEl.scrollHeight + 1000, behavior: 'smooth' })
      })
    },
    handleGo(name) {
      if (name === 'help') {
        window.open('https://www.muyuepro.com/help/docs/muyue')
      } else if (name === 'service') {
        window.open('http://47.100.117.82:82/chatIndex?kefu_id=kefu2')
      } else {
        this.$router.push({
          name: name
        })
      }
    },
    closeBackup() {
      this.showFeedback = false
      this.showWechatCode = false
      this.showWechatService = false
    },
    handleUpload() {
      this.$refs.uploadInput.click()
    },
    uploadChange(e) {
      let that = this
      const file = e.target.files[0]
      let param = new FormData()
      param.append('file', file)
      uploadFileResouce(param).then((res) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (result) => {
          that.uploadList.push({
            file: res,
            url: result.target.result
          })
        }
      })
    },
    checkFeedbackMess() {
      const { questionType, questionDesc } = this
      if (!questionType) {
        return '请选择问题类型'
      }
      if (!questionDesc) {
        return '请输入反馈描述'
      }
      return ''
    },
    removeImg(index) {
      this.uploadList.splice(index, 1)
    },
    submitFeedback() {
      const { questionType, questionDesc, uploadList, contact } = this
      const hintMess = this.checkFeedbackMess()
      if (hintMess) {
        this.$message.error(hintMess)
        return
      }
      let arr = []
      uploadList.forEach(item => {
        arr.push(item.file)
      })
      submitFeedback({
        questionType,
        desc: questionDesc,
        files: JSON.stringify(arr),
        contact
      }).then(() => {
        this.$message.success('提交成功！，感谢您的反馈')
        this.questionType = ''
        this.questionDesc = ''
        this.uploadList = []
        this.contact = ''
        this.showFeedback = false
      }).catch(err => {
        this.$message.error(err.message || '请求出错，请稍后再试')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss"
</style>
