import Vue from 'vue'
import Vuex from 'vuex'
import { localSet, localGet } from '@/utils/index'

Vue.use(Vuex)

const USER_INFO_KEY = 'USER__INFO__'
const TOKEN_VALUE = 'TOKEN_VALUE_'

export default new Vuex.Store({
  state: {
    token: "",
    userInfo: null,
    showLoading: false,
    isMobile: window.matchMedia('(max-width: 680px)').matches
  },
  getters: {
    getToken(state) {
      return state.token || localGet(TOKEN_VALUE) || ''
    },
    getUserInfo(state) {
      return state.userInfo || localGet(USER_INFO_KEY) || {}
    },
    getLoadingState(state) {
      return state.showLoading || ''
    },
    GetIsMobile(state) {
      return state.isMobile || false
    }
  },
  mutations: {
    setToken(state, val) {
      state.token = val
      localSet(TOKEN_VALUE, val)
    },
    setUserInfo(state, info) {
      state.userInfo = info
      localSet(USER_INFO_KEY, info)
    },
    setLoadingState(state, val) {
      state.showLoading = val
    }
  },
  actions: {
  },
  modules: {
  }
})
