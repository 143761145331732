export function localSet (key, value) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export function localGet (key) {
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(value)
  } catch (error) {
    return value
  }
}

export function localRemove (key) {
  window.localStorage.removeItem(key)
}

export function clearLocalAll () {
  window.localStorage.clear()
}

export function sessionSet (key, value) {
  window.sessionStorage.setItem(key, JSON.stringify(value))
}

export function sessionGet (key) {
  const value = window.sessionStorage.getItem(key)
  try {
    return JSON.parse(value)
  } catch (error) {
    return value
  }
}

export function sessionRemove (key) {
  window.sessionStorage.removeItem(key)
}

export function clearSessionAll () {
  window.sessionStorage.clear()
}
