/** axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
 import axios from 'axios'
 import QS from 'qs'
 import { Message  } from 'element-ui'
 import store from '../store/index'
 import { omit } from 'lodash-es'
 
 // axios.defaults.baseURL = 'http://127.0.0.1:8081/'
 // if (process.env.VUE_APP_ENV === 'pro') {
 //   axios.defaults.baseURL = 'http://127.0.0.1:8081/'
 // }
 
 // 请求超时时间
 axios.defaults.timeout = 600000
 
 // post请求头
 axios.defaults.headers.post['Content-Type'] =
   'application/x-www-form-urlencoded;charset=UTF-8'
 
 // 请求拦截器
 axios.interceptors.request.use(
   config => {
     // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
     // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
     const token = store.getters.getToken
     token && (config.headers.Authorization = 'Bearer ' + token)
     return config
   },
   error => {
     return Promise.reject(error)
   }
 )
 
 // 响应拦截器
 axios.interceptors.response.use(
   response => {
     store.commit('setLoadingState', false)
     if (response.status === 200 && response.data.code === 200) {
       return Promise.resolve(response.data)
     } else {
       return Promise.reject(response)
     }
   },
   // 服务器状态码不是200的情况
   error => {
     store.commit('setLoadingState', false)
     if (error.response.status) {
       switch (error.response.status) {
         case 500:
            Message({
             message: '服务器错误，请稍后重试',
             type: 'error',
             duration: 1500
           })
           // 清除token
           localStorage.removeItem('token')
           // store.commit('loginSuccess', null)
           break
         // 404请求不存在
         case 404:
           Message({
             message: '网络请求不存在',
             duration: 1500,
             type: 'error'
           })
           break
         // 其他错误，直接抛出错误提示
         default:
           Message({
             message: error.response.data.message,
             duration: 1500,
             type: 'error'
           })
       }
       return Promise.reject(error.response)
     }
   }
 )
 /**
  * get方法，对应get请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function get(url, params) {
   return new Promise((resolve, reject) => {
     axios
       .get(url, {
         params: params
       })
       .then(res => {
         resolve(res.data)
       })
       .catch(err => {
         reject(err)
       })
   })
 }
 /**
  * post方法，对应post请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function post(url, params) {
   return new Promise((resolve, reject) => {
     axios
       .post(url, QS.stringify(params))
       .then(res => {
         resolve(res.data)
       })
       .catch(err => {
         reject(err.data)
       })
   })
 }
 
 export function postJson(url, params) {
   return new Promise((resolve, reject) => {
     axios
       .post(url, params, {
         headers: {
           'Content-Type': 'application/json;charset=UTF-8'
         }
       })
       .then(res => {
         resolve(res.data)
       })
       .catch(err => {
         reject(err.data)
       })
   })
 }
 
 export function postForm(url, params) {
   return new Promise((resolve, reject) => {
     axios
       .post(url, params, {
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       })
       .then(res => {
         resolve(res.data)
       })
       .catch(err => {
         reject(err.data)
       })
   })
 }
 
 export function uploadFile(url, params) {
   const formData = new window.FormData()
 
   if (params.data) {
     Object.keys(params.data).forEach((key) => {
       if (!params.data) return
       const value = params.data[key]
       if (Array.isArray(value)) {
         value.forEach((item) => {
           formData.append(`${key}[]`, item)
         })
         return
       }
 
       formData.append(key, params.data[key])
     })
   }
   formData.append(params.name || 'file', params.file, params.filename)
   const customParams = omit(params, 'file', 'filename', 'file')
 
   Object.keys(customParams).forEach((key) => {
     formData.append(key, customParams[key])
   })
 
   return new Promise((resolve, reject) => {
     axios
       .post(url, params, {
         headers: {
           'Content-Type': 'multipart/form-data;charset=UTF-8'
         }
       })
       .then(res => {
         resolve(res.data)
       })
       .catch(err => {
         reject(err.data)
       })
   })
 }
 